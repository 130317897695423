import { reactive, defineComponent, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import OrganizationRegisterInteractor from "@/domain/usecases/OrganizationRegisterInteractor";
import DIContainer from "@/core/DIContainer";
import RoutePath from "@/commons/RoutePath";
import { useStore } from "vuex";
import AuthInteracter from "@/domain/usecases/AuthInteractor";
import { useForm } from "vee-validate";
export default defineComponent({
    name: "OrganizationRegisterCompleted",
    components: {
        Breadcrumb: Breadcrumb
    },
    setup: function () {
        var router = useRouter();
        var route = useRoute();
        var store = useStore();
        var _a = useForm(), isSubmitting = _a.isSubmitting, handleSubmit = _a.handleSubmit;
        var register_token = route.query.register_token;
        var organization_member_id = Number(route.query.organization_member_id);
        var organization_id = Number(route.query.organization_id);
        var authInteractor = DIContainer.instance.get(AuthInteracter);
        var organizationRegisterInteractor = DIContainer.instance.get(OrganizationRegisterInteractor);
        var state = reactive({
            listBreadcrumb: [
                {
                    route: '/', name: "TOP"
                },
                {
                    route: '', name: "登録完了"
                }
            ],
            isLoading: true,
            isRegisterCompleted: false
        });
        var goOrganizationDashboard = handleSubmit(function () {
            return authInteractor.authCheck().then(function (result) {
                if (result.data && result.data && result.data.user) {
                    store.commit("setUserData", result.data.user);
                    var account_name = result.data.user.name;
                    var is_compostion = result.data.user.is_composition;
                    localStorage.setItem("accountName", account_name);
                    localStorage.setItem("isComposition", is_compostion);
                }
                router.push(RoutePath.ORGANIZATION_DASHBOARD);
            }).catch(function (error) {
            });
        });
        onMounted(function () {
            organizationRegisterInteractor
                .validateTokenRegister(register_token, organization_member_id, organization_id)
                .then(function (result) {
                state.isLoading = false;
                state.isRegisterCompleted = true;
            }, function (error) {
                if (Object.keys(error.validation_errors).length) {
                    router.push(RoutePath.ORGANIZATION_REGISTER_INVALID_URL);
                }
                else {
                    router.push(RoutePath.ORGANIZATION_REGISTER_EXPIRED);
                }
                state.isLoading = false;
            });
        });
        return {
            state: state,
            isSubmitting: isSubmitting,
            goOrganizationDashboard: goOrganizationDashboard
        };
    }
});
